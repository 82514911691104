import { datadogRum } from '@datadog/browser-rum';
import type { RumInitConfiguration } from '@datadog/browser-rum';

import type { RouteMatcher } from '@xing-com/crate-core-router';
import type { ExceptionCapturer } from '@xing-com/crate-xinglet';

export function setupDataDog(
  matchRoute: RouteMatcher,
  {
    applicationId,
    clientToken,
    service = 'crate-app',
    sessionSampleRate,
    version,
    ...options
  }: Partial<RumInitConfiguration> = {}
): ExceptionCapturer {
  const env = {
    'www.xing.com': 'production' as const,
    'preview.xing.com': 'preview' as const,
  }[document.location.hostname];

  if (!env || !applicationId || !clientToken) {
    return (error, context) => {
      console.error(...[error, context].filter(Boolean));
    };
  }

  datadogRum.init({
    allowedTracingUrls: [
      /^https:\/\/.*?\.xing\.com/,
      /^https:\/\/.*?\.nwse.io/,
    ],
    applicationId,
    clientToken,
    defaultPrivacyLevel: 'mask',
    env,
    service,
    sessionReplaySampleRate: 0,
    sessionSampleRate,
    site: 'datadoghq.eu',
    version,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    ...options,
    beforeSend: (event, _context) => {
      const { route, owner } = matchRoute(window.location.pathname);

      if (route) event.view.name = route;
      if (owner) Object.assign((event.context ??= {}), { owner });

      return true;
    },
  });

  datadogRum.setGlobalContextProperty('cost_center', '11131');
  datadogRum.setGlobalContextProperty('application', 'crate');

  return (error, context) => {
    datadogRum.addError(error, context);
  };
}
