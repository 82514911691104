import { pathToRegexp } from 'path-to-regexp';

import type { ManifestMap } from '@xing-com/crate-core-assets';

export const CATCH_ALL_ROUTE = '/*splat';
export const KNOWN_SITES = ['xing', 'lebenslauf', 'xing-login'] as const;
export type Site = (typeof KNOWN_SITES)[number];

export type RouteInfo = {
  route: string;
  owner: string;
  site: Site;
  xinglet: string;
};

function sanitizeRoute(route: string): [string, Site] {
  const parts = route.match(/^site:(.*?)\/(.*)$/)?.slice(1) ?? ['xing', route];
  const [site] = parts;
  let [, path] = parts;

  if (!path.startsWith('/')) path = `/${path}`;
  if (path.endsWith('/*')) path = path.replace(/\/\*$/, CATCH_ALL_ROUTE);

  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  return [path, site as Site];
}

export function getPath(url: string): string {
  return url.split(/[?#]/, 1)[0];
}

export function extractRoutes(manifestMap: ManifestMap): RouteInfo[] {
  const routes = Object.entries(manifestMap).flatMap(([xinglet, manifest]) => {
    const { contributes = {}, owner = 'unknown' } = manifest.metadata;
    const { route, routes } = contributes;

    return [...(route ? [route] : []), ...(routes ?? [])].map(
      (route): RouteInfo => {
        const [path, site] = sanitizeRoute(route);

        return { route: path, owner, site, xinglet };
      }
    );
  });

  // NOTE: This is probably not enough but it's pretty good as the routes will
  // be sorted like this:
  //   "/xingternal/terms/:slug",
  //   "/xingternal/terms",
  //   ...
  //   "/people/*",
  //   "/people",
  //   ...
  //   "/support/contact/success",
  //   "/support/contact/:topic/:subject",
  //   "/support/contact/:topic",
  //   ...
  //   "/activity",
  //   "/*",
  //   "/"
  // We will then iterate over all routes until we find the first matching.
  return routes.sort((a, b) => {
    return b.route.localeCompare(a.route);
  });
}

export type RouteMatcher = (url: string) => RouteInfo;

export function createRouteMatcher(manifestMap: ManifestMap): RouteMatcher {
  const routes = extractRoutes(manifestMap).map(
    ({ route, owner, site, xinglet }) => {
      const { regexp } = pathToRegexp(route);

      return {
        route,
        owner,
        regexp,
        site,
        xinglet,
      };
    }
  );

  return (url) => {
    const entry = routes.find(({ regexp }) => regexp.test(getPath(url)));
    const {
      route = CATCH_ALL_ROUTE,
      owner = 'unknown',
      site = 'xing',
      xinglet = 'unknown',
    } = entry ?? {};

    return { route, owner, site, xinglet };
  };
}
