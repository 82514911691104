import './bootstrap';

import { registerLogjamTracking } from '@xing-com/crate-core-fetch/src/browser/logjam';
import { createRouteMatcher } from '@xing-com/crate-core-router';
import type { ExceptionCapturer } from '@xing-com/crate-xinglet';
import type { GlobalScope } from '@xing-com/crate-xinglet/internal';

import { setupDataDog } from './datadog';
import { loadManifest } from './load-manifest';
import { render } from './render';

declare const globalThis: GlobalScope;

(async () => {
  let captureException: ExceptionCapturer | undefined;

  try {
    const {
      startXinglets = [],
      dataDog,
      serverData = {},
      ...config
    } = globalThis.crate;

    const manifestMap = await loadManifest(config);
    const routeMatcher = createRouteMatcher(manifestMap);
    captureException = setupDataDog(routeMatcher, dataDog);
    registerLogjamTracking(routeMatcher, config);

    await render({
      captureException,
      config,
      manifestMap,
      startXinglets,
      serverData,
    });
  } catch (error) {
    captureException?.(error);
    throw error;
  }
})().catch((error) => {
  console.error(error);
  const pre = document.createElement('pre');
  const text = document.createTextNode(error.stack ?? error);
  pre.appendChild(text);
  document.getElementById('app')?.replaceWith(pre);
});
